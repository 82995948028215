import React from "react"
import {
  DiscussBox,
  DefinitionBox,
  ToSeeBox,
  ActivityBox,
  CopyrightBox,
  WhoWasBox
} from "../../../components/boxes"
import { BreadCrumbs, Figure, Page, SmallSectionHeader, WhereNext } from "../../../components/page"
import Layout from "../../../layout"
import * as theme from "../../../theme"
import headerImage from "../section-header.jpg"
import henry8 from "./henry-8.png"
import worksheets from "../worksheets.svg"
import { withPrefix } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

const Reformation = () => (
  <Layout title="The Reformation in England">
    <SmallSectionHeader
      color={theme.colors.purple.dark}
      image={headerImage}
      to="/a-walk-through-time"
    >
      A walk through time
    </SmallSectionHeader>
    <BreadCrumbs items={[
      { to: '/a-walk-through-time', name: 'A walk through time' },
      { name: 'The Reformation in England' }
    ]} />
    <Page>
      <h1>The Reformation in England</h1>
      <div className="page-left">
        <p>
          In the year 1534 King Henry VIII declared himself head of the Church in England and took control of church affairs. From the year 1536 his officials went around the country closing monasteries and claiming their wealth for the crown.
        </p>
        <p>
          The shrine of Saint Thomas was especially problematic for King Henry VIII. By this time, Becket was being remembered as someone who lost his life standing up to the royal authority – something Henry did not want to encourage. Becket was declared a banned saint and in the year 1538 Becket’s shrine was destroyed.
        </p>
        <p>
          The monastery at Canterbury Cathedral was dissolved (closed) in the year 1540. In the year 1541, Henry installed a new Dean and Chapter at Canterbury Cathedral. Of the many monks who had lived there only 28 remained to join the new community.
        </p>
      </div>
      <div className="page-right">
        <Figure
          caption="St. Thomas’ name appears censored in this breviary (an important book that allows people to follow psalms and hymns) after the Reformation. – Canterbury Cathedral Library, H/L-5-5"
        >
          <StaticImage
            src="./manuscript.jpg"
            alt="Manuscript"
          />
        </Figure>
        <DefinitionBox title="Reformation">
          The Reformation is the process by which the Church of England split from the Catholic Church in Rome.
        </DefinitionBox>
      </div>

      <DiscussBox>
        Why didn’t Henry VIII like the fact that Becket was seen as someone who stood up against Royal authority?
      </DiscussBox>

      <h2>The destruction of the shrine of St Thomas</h2>
      <p className="page-left">
        The shrine of Saint Thomas was especially problematic for King Henry VIII.
      </p>
      <Figure
        caption="These fragments are believed to be from Becket’s jewel-encrusted marble shrine. – 01229.1 Canterbury Cathedral Collections"
        className="page-right row-2"
      >
        <img
          alt="Fragment"
          src="./fragment.jpg"
        />
      </Figure>

      <DiscussBox>
        How did the reformation change life in the Cathedral?
      </DiscussBox>


      <p className="page-left">
        Henry VIII’s role as the Supreme Head of the Church in England meant that he was independent of the Pope’s authority in Rome. In 1539 the first official Bible in English was produced. The most striking part of the this Bible the frontispiece which features a heavily symbolic image of the new Church of England. It shows the King presenting the Bible to clergy and people, a forceful message about royal supremacy.
      </p>

      <Figure
        caption="This image shows the frontispiece (cover page) of the Great Bible. Henry VIII can clearly be seen handing out copies of the Bible (written in English and not Latin) to important members of the clergy. – W/S-17-1 Canterbury Cathedral Library"
      >
        <StaticImage
          alt="Cover Page"
          src="./cover.jpg"
        />
      </Figure>

      <DefinitionBox title="Frontispiece">
        A picture at the beginning of a book, on the page opposite the page with the title on it.
      </DefinitionBox>


      <WhoWasBox
        name="King Henry VIII"
        dates="b. 1491 - d. 1547"
        image={{
          src: henry8,
          alt: "King Henry VIII",
        }}
      >
        <p>In 1509 AD Henry VIII had married the Catholic Catherine of Aragon but she had failed to give him a male heir. Henry wanted to divorce Catherine and instead marry Anne Boleyn, but the Pope condemned his actions. In order to pursue his divorce Henry separated himself from the Catholic Church and in 1534 AD he declared himself head of the Church of England.</p>
        <p>Over 800 monasteries and religious houses were dissolved by Henry during the reformation (when the Church of England split from the Catholic Church in Rome). The wealth from these monasteries helped Henry fund a war with France.</p>
      </WhoWasBox>

      <ToSeeBox
        items={[
          {
            location: "Trinity Chapel",
            items: [
              {
                title: "Site of the shrine of Saint Thomas",
                content:
                  "Saint Thomas’s shrine was located here from 1220 -1538. A candle marks the spot. The shrine would have been loaded with offerings of gold, silver and jewellery brought by the pilgrims who approached the shrine on their knees.",
              },
            ],
          },
          {
            location: "Christ Church Gate",
            items: [
              {
                title: "Christ Church Gate",
                content:
                  "Built between 1504 and 1521 probably in honour of Prince Arthur, King Henry VIII’s elder brother. You can see the Tudor coats-of-arms. On Arthur’s death Henry married his brother’s wife Katherine of Aragon. The gate was damaged in 1643 by the Puritans (a group of Protestants in the sixteenth and seventeenth centuries who believed in a simpler form of worship) and later restored.",
              },
            ],
          },
          {
            location: "Monastery ruins",
            items: [
              {
                title: "Monastery ruins",
                content:
                  "The ruins of the monastery’s refectory kitchen, brewery and bakery can still be seen.",
              },
            ],
          },
        ]}
      />

      <h2>Activities</h2>
      <ActivityBox
        title="A Walk Through Time activity sheets"
        image={
          <img src={worksheets} alt="Worksheets" />
        }
        link={withPrefix('/AWalkThroughTime.pdf')}
      >
        Click here to find a programme of curriculum linked classroom activities and resources to support the ‘A walk through time’ resource.
      </ActivityBox>

      <WhereNext
        items={[
          { name: "World War Two", to: "/a-walk-through-time/ww2" },
          { name: "Saint Augustine", to: "/a-walk-through-time/st-augustine" },
          { name: "The Vikings", to: "/a-walk-through-time/vikings" },
        ]}
      />

      <hr />

      <CopyrightBox />
    </Page>
  </Layout>
)

export default Reformation